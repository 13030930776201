import React, { useEffect, useRef, useState } from "react";
import {
  Field,
  Form,
  Formik
} from "formik";
import * as Yup from "yup";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import ColorLensIcon from '@material-ui/icons/ColorLens';
import { SketchPicker } from 'react-color';
import toastError from "../../errors/toastError";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  colorPreview: {
    width: 20,
    height: 20,
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },
  colorPicker: {
    position: 'absolute',
    zIndex: 2,
  }
}));

const QueueSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  color: Yup.string().min(3, "Too Short!").max(9, "Too Long!").required(),
  greetingMessage: Yup.string(),
  startWork: Yup.string(),
  endWork: Yup.string(),
  absenceMessage: Yup.string()
});

const QueueModal = ({ open, onClose, queueId }) => {
  const classes = useStyles();
  const initialState = {
    name: "",
    color: "#5C59A0",
    greetingMessage: "",
    startWork: "",
    endWork: "",
    absenceMessage: ""
  };
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [color, setColor] = useState("#5C59A0");
  const [queue, setQueue] = useState(initialState);

  useEffect(() => {
    (async () => {
      if (!queueId) return;
      try {
        const { data } = await api.get(`/queue/${queueId}`);
        setQueue(prevState => {
          return { ...prevState, ...data };
        });
      } catch (err) {
        toastError(err);
      }
    })();

    return () => {
      setQueue(initialState);
    };
  }, [queueId, open]);

  const handleClose = () => {
    onClose();
    setQueue(initialState);
  };

  const handleSaveQueue = async values => {
    try {
      if (queueId) {
        await api.put(`/queue/${queueId}`, values);
      } else {
        await api.post("/queue", values);
      }
      toast.success(`${i18n.t("queueModal.notification.title")}`);
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} scroll="paper" disableEnforceFocus>
        <DialogTitle>
          {queueId
            ? `${i18n.t("queueModal.title.edit")}`
            : `${i18n.t("queueModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={queue}
          enableReinitialize={true}
          validationSchema={QueueSchema}
          onSubmit={(values, actions) => {
            handleSaveQueue(values);
            actions.setSubmitting(false);
          }}
        >
          {({ touched, errors, isSubmitting, values, setFieldValue }) => (
            <Form>
              <DialogContent dividers>
                <Field
                  as={TextField}
                  label={i18n.t("queueModal.form.name")}
                  autoFocus
                  name="name"
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                />
                <TextField
                  label="Color"
                  onClick={() => setShowColorPicker(show => !show)}
                  value={values.color || color}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div className={classes.colorPreview} style={{ backgroundColor: values.color || color }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="color picker">
                          <ColorLensIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={e => setFieldValue("color", e.target.value)}
                />
                {showColorPicker && (
                  <div style={{ position: 'absolute', zIndex: 2 }}>
                    <SketchPicker color={color} onChangeComplete={(updatedColor) => {
                      setColor(updatedColor.hex);
                      setFieldValue("color", updatedColor.hex);
                    }} />
                  </div>
                )}
                <Field
                  as={TextField}
                  label={i18n.t("queueModal.form.greetingMessage")}
                  type="greetingMessage"
                  multiline
                  minRows={4}
                  fullWidth
                  name="greetingMessage"
                  error={touched.greetingMessage && Boolean(errors.greetingMessage)}
                  helperText={touched.greetingMessage && errors.greetingMessage}
                  variant="outlined"
                  margin="dense"
                />
                <div className={classes.container} noValidate>
                  <Field
                    as={TextField}
                    label={i18n.t("queueModal.form.startWork")}
                    type="time"
                    defaultValue="08:00"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 600, // 5 min
                    }}
                    fullWidth
                    name="startWork"
                    error={touched.startWork && Boolean(errors.startWork)}
                    helperText={touched.startWork && errors.startWork}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                  />
                  <Field
                    as={TextField}
                    label={i18n.t("queueModal.form.endWork")}
                    type="time"
                    defaultValue="18:00"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 600, // 5 min
                    }}
                    fullWidth
                    name="endWork"
                    error={touched.endWork && Boolean(errors.endWork)}
                    helperText={touched.endWork && errors.endWork}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                  />
                </div>
                <Field
                  as={TextField}
                  label={i18n.t("queueModal.form.absenceMessage")}
                  type="absenceMessage"
                  multiline
                  minRows={2}
                  fullWidth
                  name="absenceMessage"
                  error={touched.absenceMessage && Boolean(errors.absenceMessage)}
                  helperText={touched.absenceMessage && errors.absenceMessage}
                  variant="outlined"
                  margin="dense"
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("queueModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {queueId
                    ? `${i18n.t("queueModal.buttons.okEdit")}`
                    : `${i18n.t("queueModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default QueueModal;
